<template>
  <modal-dialog :show="show" @close="close">
    <h1 class="text-center text-2xl font-bold mb-4">
      Egg Batch
    </h1>
    <div class="flex mb-4">
      <input-field
        v-model="form.start_date"
        type="date"
        label="Start Date"
        class="w-1/2 mr-4"
        :error="form.errors.get('start_date')"
      />
      <input-field
        v-model="form.start_time"
        type="time"
        label="Start Time"
        class="w-1/2"
        :error="form.errors.get('start_time')"
      />
    </div>
    <div class="flex mb-4">
      <input-field
        class="w-1/2 mr-4"
        v-model="form.end_date"
        type="date"
        label="End date"
        :error="form.errors.get('end_date')"
      />
      <input-field
        class="w-1/2"
        v-model="form.end_time"
        type="time"
        label="End time"
        :error="form.errors.get('end_time')"
      />
    </div>
    <div class="flex mb-4">
      <input-field
        class="w-1/2 mr-4"
        v-model="form.weight"
        label="Weight (gram)"
        :error="form.errors.get('weight')"
      />
      <div class="w-1/2"></div>
    </div>

    <p class="text-gray-700 mb-4">Timezone: {{ form.timezone }}</p>

    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
import dayjs from '@/plugins/dayjs';

export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    eggBatch: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        start_date: null,
        start_time: null,
        end_date: null,
        end_time: null,
        weight: null,
        timezone: 'UTC'
      })
    };
  },
  mounted() {
    this.form.timezone = dayjs.tz.guess();
  },
  watch: {
    eggBatch: {
      handler(value) {
        if (value === null) {
          this.form.reset();
          return;
        }

        this.form.start_date = dayjs
          .utc(value.started_at)
          .local()
          .format('YYYY-MM-DD');
        this.form.start_time = dayjs
          .utc(value.started_at)
          .local()
          .format('HH:mm');
        this.form.end_date = dayjs
          .utc(value.ended_at)
          .local()
          .format('YYYY-MM-DD');
        this.form.end_time = dayjs
          .utc(value.ended_at)
          .local()
          .format('HH:mm');
        this.form.weight = value.weight;
      },
      immediate: true
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.eggBatch ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        await this.form.submit('post', '/egg-batches');

        this.$emit('create');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit('patch', `/egg-batches/${this.eggBatch.id}`);

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
