<template>
  <div class="flex flex-col items-center">
    <div>
      <div v-for="(time, index) in time_periods" :key="index">
        <input-field
          class="w-48 mb-4"
          type="time"
          :value="time.value"
          @input="time.value = $event"
        />
      </div>
      <p v-if="error" class="text-xs text-red-500">{{ error }}</p>
    </div>
    <div class="mb-8">
      <button @click="addTime" class="py-2 w-48 text-sm border">
        add time
      </button>
    </div>
  </div>
</template>

<script>
import isEmpty from 'lodash.isempty';

export default {
  props: {
    value: {
      type: Array,
      default: () => []
    },
    initial_time_periods: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      time_periods: []
    };
  },
  watch: {
    time_periods: {
      handler(time_periods) {
        time_periods = time_periods.filter(
          time => !isEmpty(time.value) && typeof time.value === 'string'
        );
        this.$emit('input', time_periods);
      },
      deep: true
    }
  },
  mounted() {
    if (isEmpty(this.initial_time_periods)) {
      this.addTime();

      return;
    }

    this.initial_time_periods.split(',').forEach(time => {
      if (isEmpty(time)) {
        return;
      }
      this.addTime(time);
    });
  },
  methods: {
    addTime(time = null) {
      this.time_periods.push({ value: time });
    }
  }
};
</script>
