<template>
  <div class="flex justify-between items-center px-4 group hover:bg-gray-100">
    <div class="py-1">
      <span
        class="inline-block w-2 h-2 rounded-full mr-1"
        :class="{
          'bg-blue-500': pupaeDoor.manually_opened_at,
          'bg-red-500': pupaeDoor.operation_halted_at,
          'bg-green-500':
            !pupaeDoor.manually_opened_at && !pupaeDoor.operation_halted_at
        }"
      ></span>
      <span> Door {{ number }} </span>
    </div>
    <div class="flex text-xs">
      <button
        v-if="pupaeDoor.operation_halted_at"
        @click="resolve"
        class="border-2 rounded bg-white text-gray-600 hover:border-blue-500 hover:text-blue-500 transition-all duration-300 font-bold px-1 mr-2"
      >
        resolve
      </button>
      <div
        v-if="showControls || pupaeDoor.operation_halted_at"
        :class="
          pupaeDoor.operation_halted_at ? '' : 'hidden group-hover:inline-block'
        "
      >
        <button
          v-if="openedPupaeDoors.includes(pupaeDoor.id)"
          class="border-2 rounded bg-white text-red-400 border-red-400 hover:border-red-600 hover:text-red-600 transition-all duration-300 font-bold px-1"
          @click="close"
        >
          close
        </button>
        <button
          v-else
          class="border-2 rounded bg-white text-blue-400 border-blue-400 hover:border-blue-600 hover:text-blue-600 transition-all duration-300 font-bold px-1"
          @click="open"
        >
          open
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rack: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    pupaeDoor: {
      type: Object,
      required: true
    },
    showControls: {
      type: Boolean,
      required: true
    },
    openedPupaeDoors: {
      type: Array,
      required: true
    }
  },
  methods: {
    open() {
      this.axios.post(
        `/racks/${this.rack.id}/pupae-doors/${this.pupaeDoor.id}`
      );
    },
    close() {
      this.axios.delete(
        `/racks/${this.rack.id}/pupae-doors/${this.pupaeDoor.id}`
      );
    },
    resolve() {
      this.axios.post(`pupae-doors/${this.pupaeDoor.id}/resolve-operation`);
    }
  }
};
</script>
