<template>
  <div class="py-4 shadow-md rounded-lg bg-white">
    <div class="flex justify-between items-center px-4">
      <div class="font-bold flex">
        <span>{{ rack.name }}</span>
        <span class="font-normal ml-2 text-gray-600">(#{{ rack.id }})</span>
      </div>

      <div>
        <button
          class="text-xs text-gray-600 font-bold hover:text-blue-500 mr-4"
          @click="showDeleteDialog = true"
        >
          delete
        </button>
        <button
          class="text-xs text-gray-600 font-bold hover:text-blue-500 mr-4"
          @click="$emit('sync-doors')"
        >
          sync
        </button>
        <button
          class="text-xs text-gray-600 font-bold hover:text-blue-500"
          @click="rackFormIsVisible = true"
        >
          edit
        </button>
      </div>
    </div>

    <div
      v-if="syncing"
      class="px-4 py-1 font-bold text-white text-xs mt-2 bg-blue-500"
    >
      syncing...
    </div>
    <div
      v-else
      class="px-4 py-1 font-bold text-white text-xs mt-2"
      :class="isRackConnected ? 'bg-green-500' : 'bg-red-600'"
    >
      {{ isRackConnected ? 'connected' : 'disconnected' }}
    </div>

    <div class="px-4 mt-2">
      <div class="font-bold">Pupae Doors:</div>
      <div class="flex justify-between items-end">
        <div class="text-xs text-gray-600">Auto mode</div>
        <button>
          <fa-icon
            v-if="is_pupae_door_manual_mode_enabled"
            :icon="['fas', 'toggle-off']"
            class="text-xl text-gray-600"
            @click="switchPupaeDoorToManualMode(false)"
          />
          <fa-icon
            v-else
            :icon="['fas', 'toggle-on']"
            class="text-xl text-blue-500"
            @click="switchPupaeDoorToManualMode(true)"
          />
        </button>
      </div>
      <div class="text-xs text-gray-600 mb-2">
        <div class="flex justify-between">
          <div>Opens at:</div>
          <div>{{ rack.time_periods.split(',').join(', ') }}</div>
        </div>
        <div class="flex justify-between">
          <div>On Weekdays:</div>
          <div>{{ weekdaysList }}</div>
        </div>
      </div>
    </div>
    <rack-pupae-door
      v-for="(pupae_door, index) in rack.pupae_doors"
      :key="`pupae-door-${pupae_door.id}`"
      :number="index + 1"
      :rack="rack"
      :pupae-door="pupae_door"
      :opened-pupae-doors="opened_pupae_doors"
      :show-controls="is_pupae_door_manual_mode_enabled"
      class="py-1"
    />

    <hr class="my-2" />
    <div class="px-4 mb-2">
      <div class="font-bold">Egg Doors:</div>
      <div class="flex justify-between items-end">
        <div class="text-sm text-gray-600">Auto mode</div>
        <button>
          <fa-icon
            v-if="is_egg_door_manual_mode_enabled"
            :icon="['fas', 'toggle-off']"
            class="text-xl text-gray-600"
            @click="switchEggDoorToManualMode(false)"
          />
          <fa-icon
            v-else
            :icon="['fas', 'toggle-on']"
            class="text-xl text-blue-500"
            @click="switchEggDoorToManualMode(true)"
          />
        </button>
      </div>
    </div>
    <rack-egg-door
      v-for="(egg_door, index) in rack.egg_doors"
      :key="`egg-door-${egg_door.id}`"
      :number="index + 1"
      :rack="rack"
      :show-controls="is_egg_door_manual_mode_enabled"
      :egg-door="egg_door"
      :opened-egg-doors="opened_egg_doors"
      class="py-1"
    />

    <rack-form
      :show="rackFormIsVisible"
      :fly-room="flyRoom"
      :rack-manager="rackManager"
      :rack="rack"
      @edit="onDone"
      @close="onClose"
    />

    <confirm-action-dialog
      :show="showDeleteDialog"
      type="delete"
      width="600"
      :resource="rack"
      :action="deleteRack"
      @close="onClose"
      @done="onDone"
    >
      <template slot="title"> Delete Rack #{{ rack.id }}? </template>
      <p>
        Are you sure you want to delete selected rack
        <strong>{{ rack.name }}</strong
        >?
      </p>
    </confirm-action-dialog>
  </div>
</template>

<script>
import RackPupaeDoor from './RackPupaeDoor';
import RackEggDoor from './RackEggDoor';
import RackForm from './RackForm';

export default {
  components: { RackForm, RackEggDoor, RackPupaeDoor },
  props: {
    flyRoom: {
      type: Object,
      required: true
    },
    rackManager: {
      type: Object,
      required: true
    },
    rack: {
      type: Object,
      required: true
    },
    isConnected: {
      type: Boolean,
      required: true
    },
    doorStatus: {
      type: Object,
      default: null
    },
    syncing: {
      type: Boolean,
      required: true
    },
    connectedRacks: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      is_pupae_door_manual_mode_enabled: !!this.rack
        .pupae_door_manual_mode_enabled_at,
      is_egg_door_manual_mode_enabled: !!this.rack
        .egg_door_manual_mode_enabled_at,
      rackFormIsVisible: false,
      opened_pupae_doors: [],
      opened_egg_doors: [],
      showDeleteDialog: false
    };
  },
  mounted() {
    this.connect();
  },
  watch: {
    doorStatus: {
      handler(status) {
        if (!status) {
          return;
        }

        const result = status.find(rack => rack.rack_id === this.rack.id);

        if (!result) {
          return;
        }

        this.opened_pupae_doors = result.opened_pupae_doors;
        this.opened_egg_doors = result.opened_egg_doors;
      },
      immediate: true
    }
  },
  computed: {
    isRackConnected() {
      return this.isConnected && this.connectedRacks?.includes(this.rack.id);
    },
    weekdaysList() {
      if (!this.rack.weekdays) {
        return;
      }

      const weekdays = [
        { name: 'Monday', short_name: 'Mon', value: 0 },
        { name: 'Tuesday', short_name: 'Tue', value: 1 },
        { name: 'Wednesday', short_name: 'Wed', value: 2 },
        { name: 'Thursday', short_name: 'Thu', value: 3 },
        { name: 'Friday', short_name: 'Fri', value: 4 },
        { name: 'Saturday', short_name: 'Sat', value: 5 },
        { name: 'Sunday', short_name: 'Sun', value: 6 }
      ];

      return this.rack.weekdays
        .split(',')
        .map(
          weekday =>
            weekdays.find(day => day.value === parseInt(weekday)).short_name
        )
        .join(', ');
    }
  },
  methods: {
    onClose() {
      this.rackFormIsVisible = false;
      this.showDeleteDialog = false;
    },
    onDone() {
      this.onClose();
      this.$emit('reload');
    },
    async deleteRack() {
      await this.axios.delete(
        `/fly-rooms/${this.flyRoom.id}/rack-managers/${this.rackManager.id}/racks/${this.rack.id}`
      );
    },
    async switchPupaeDoorToManualMode(enable) {
      await this.axios.post(`/racks/${this.rack.id}/pupae-door-mode`, {
        enable_manual_mode: enable
      });
      this.is_pupae_door_manual_mode_enabled = enable;
    },
    async switchEggDoorToManualMode(enable) {
      await this.axios.post(`/racks/${this.rack.id}/egg-door-mode`, {
        enable_manual_mode: enable
      });
      this.is_egg_door_manual_mode_enabled = enable;
    },
    connect() {
      this.$echo
        .channel(`dashboard.rack.${this.rack.id}`)
        .listen('.rack.status', message => {
          this.$emit('update-rack', message.rack);
        })
        .listen('.pupae-door.status', message => {
          const id = message.pupaeDoor.id;
          const index = this.opened_pupae_doors.indexOf(id);
          const status = message.status;

          if (status === 'opened' && index < 0) {
            this.opened_pupae_doors.push(id);
            return;
          }

          if (status === 'closed' && index >= 0) {
            this.opened_pupae_doors.splice(index, 1);
          }
        })
        .listen('.egg-door.status', message => {
          const id = message.eggDoor.id;
          const index = this.opened_egg_doors.indexOf(id);
          const status = message.status;

          if (status === 'opened' && index < 0) {
            this.opened_egg_doors.push(id);
            return;
          }

          if (status === 'closed' && index >= 0) {
            this.opened_egg_doors.splice(index, 1);
          }
        });
    }
  }
};
</script>
