<template>
  <div>
    <section-title>Mating Overview</section-title>
    <implementation-pending />
  </div>
</template>

<script>
export default {};
</script>
