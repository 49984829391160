<template>
  <modal-dialog :show="show" width="500">
    <div v-if="eggBatch" class="mb-4 leading-loose">
      <h1 class="font-bold text-xl mb-4">Mark egg batch as completed</h1>
      <p>Egg batch ID: {{ eggBatch.id }}</p>
      <p>Start date: <local-date :date="eggBatch.started_at" /></p>
      <p>End date: <local-date :date="eggBatch.ended_at" /></p>
      <p>Estimated count: {{ eggBatch.estimated_count }}</p>
      <p>Hatching rate: {{ eggBatch.hatching_rate }}%</p>
      <p>Current status: {{ eggBatch.status }}</p>
    </div>

    <div class="text-center">
      <primary-button :loading="loading" @click="update" class="w-full mb-4">
        Mark as completed
      </primary-button>
      <button @click="$emit('close')">close</button>
    </div>
  </modal-dialog>
</template>

<script>
import LocalDate from '@/components/global/LocalDate';
import { handleFailedResponse } from '@/core/helpers';

export default {
  components: { LocalDate },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    eggBatch: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      loading: false
    };
  },
  methods: {
    async update() {
      this.processing = true;

      try {
        await this.axios.patch(`/egg-batches/${this.eggBatch.id}/status`, {
          status: 'hatching-completed'
        });

        this.$emit('update');
      } catch (e) {
        handleFailedResponse(e);
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
