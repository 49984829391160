<template>
  <div>
    <div class="flex items-center mb-4">
      <div
        class="w-2 h-2 rounded-full mr-2"
        :class="is_connected ? 'bg-green-500' : 'bg-red-500'"
      ></div>
      <h3>{{ rackManager.name }}</h3>
      <div class="flex space-x-4 ml-4">
        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="rackManagerFormIsVisible = true"
        >
          edit
        </button>
        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="showDeleteDialog = true"
        >
          delete
        </button>
        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="tokenFormIsVisible = true"
        >
          generate token
        </button>
        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="rackFormIsVisible = true"
        >
          add rack
        </button>
      </div>
    </div>

    <div class="grid gap-4 grid-cols-4">
      <the-rack
        v-for="rack in racks"
        :key="rack.id"
        :fly-room="flyRoom"
        :rack-manager="rackManager"
        :rack="rack"
        :is-connected="is_connected"
        :door-status="door_status"
        :connected-racks="connected_racks"
        :syncing="syncing"
        class="w-64"
        @sync-doors="syncDoors"
        @update-rack="onRackUpdate"
        @reload="$emit('reload')"
      />
    </div>

    <rack-form
      :show="rackFormIsVisible"
      :fly-room="flyRoom"
      :rack-manager="rackManager"
      @create="onDone"
      @edit="onDone"
      @close="onClose"
    />

    <regenerate-rack-manager-token
      :show="tokenFormIsVisible"
      :fly-room="flyRoom"
      :rack-manager="rackManager"
      @close="tokenFormIsVisible = false"
    />

    <rack-manager-form
      :show="rackManagerFormIsVisible"
      :fly-room="flyRoom"
      :rack-manager="rackManager"
      @create="onDone"
      @edit="onDone"
      @close="onClose"
    />

    <confirm-action-dialog
      :show="showDeleteDialog"
      type="delete"
      width="600"
      :resource="rackManager"
      :action="deleteRackManager"
      @close="onClose"
      @done="onDone"
    >
      <template slot="title">
        Delete Rack Controller #{{ rackManager.id }}?
      </template>
      <p>
        All the racks assigned with this controller will also be deleted. Are
        you sure you want to delete selected rack controller
        <strong>{{ rackManager.name }}</strong
        >?
      </p>
    </confirm-action-dialog>
  </div>
</template>

<script>
import TheRack from './TheRack';
import RackForm from './RackForm';
import RegenerateRackManagerToken from './RegenerateRackManagerToken';
import RackManagerForm from './RackManagerForm';
export default {
  components: {
    RackManagerForm,
    RegenerateRackManagerToken,
    RackForm,
    TheRack
  },
  props: {
    flyRoom: {
      type: Object,
      required: true
    },
    rackManager: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rackFormIsVisible: false,
      tokenFormIsVisible: false,
      rackManagerFormIsVisible: false,
      updatedRacks: [],
      connected_racks: [],
      syncing: false,
      pong_received_at: null,
      is_connected: false,
      door_status: null,
      showDeleteDialog: false
    };
  },
  mounted() {
    this.connect();
    this.checkConnection();
    this.syncDoors();
  },
  computed: {
    racks() {
      return this.rackManager.racks.map(rack => {
        const updatedRack = this.updatedRacks.find(r => r.id === rack.id);

        return updatedRack || rack;
      });
    }
  },
  methods: {
    onClose() {
      this.rackFormIsVisible = false;
      this.rackManagerFormIsVisible = false;
      this.tokenFormIsVisible = false;
      this.showDeleteDialog = false;
    },
    onDone() {
      this.onClose();
      this.$emit('reload');
    },
    async deleteRackManager() {
      await this.axios.delete(
        `/fly-rooms/${this.flyRoom.id}/rack-managers/${this.rackManager.id}`
      );
    },
    onRackUpdate(rack) {
      if (this.updatedRacks.find(r => r.id === rack.id)) {
        this.updatedRacks = this.updatedRacks.map(r => {
          return r.id === rack.id ? rack : r;
        });

        return;
      }

      this.updatedRacks.push(rack);
    },
    async syncDoors() {
      this.syncing = true;
      await this.axios.post(`/rack-managers/${this.rackManager.id}/sync-doors`);
    },
    connect() {
      this.$echo
        .channel(`dashboard.rack-manager.${this.rackManager.id}`)
        .listen('.pong', message => {
          this.pong_received_at = message.time;
          this.connected_racks = message.connected_racks;
        })
        .listen('.sync.door_status', message => {
          this.door_status = message.door_status;
          this.syncing = false;
        });
    },
    async ping() {
      await this.axios.post(`/rack-managers/${this.rackManager.id}/ping`);
    },
    checkConnection() {
      setInterval(() => {
        if (this.pong_received_at === null) {
          this.is_connected = false;
          return;
        }

        const diff_in_seconds =
          (new Date().getTime() - new Date(this.pong_received_at).getTime()) /
          1000;

        this.is_connected = diff_in_seconds < 60;
      }, 3000);
    }
  }
};
</script>
