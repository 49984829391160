<template>
  <modal-dialog :show="show" @close="close">
    <h1 class="text-center text-2xl font-bold mb-4">
      Fly Room
    </h1>
    <input-field
      v-model="form.name"
      label="Name"
      class="mb-6"
      :error="form.errors.get('name')"
    />
    <input-field
      class="mb-6"
      v-model="form.description"
      label="Description (optional)"
      :error="form.errors.get('description')"
    />
    <div class="text-center">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      required: true
    },
    flyRoom: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      form: this.$form({
        name: null,
        description: null
      })
    };
  },
  watch: {
    flyRoom: {
      immediate: true,
      handler(value) {
        if (value === null) {
          this.form.reset();
          return;
        }

        this.form.name = value.name;
        this.form.description = value.description;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.flyRoom ? this.edit() : this.create();
    },
    async create() {
      this.processing = true;
      try {
        await this.form.submit('post', '/fly-rooms');

        this.$emit('create');
        this.form.reset();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        await this.form.submit('patch', `/fly-rooms/${this.flyRoom.id}`);

        this.$emit('edit');
        this.form.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
