<template>
  <tr>
    <td class="border px-4 py-2">{{ eggBatch.id }}</td>
    <td class="border px-4 py-2 text-left">
      <local-date :date="eggBatch.started_at" />
    </td>
    <td class="border px-4 py-2 text-left">
      <local-date :date="eggBatch.ended_at" />
    </td>
    <td class="border px-4 py-2 text-right">
      {{ eggBatch.estimated_count }}
    </td>
    <td class="border px-4 py-2 text-right">{{ eggBatch.larvae_count }}</td>
    <td class="border px-4 py-2 text-right">{{ eggBatch.hatching_rate }}%</td>
    <td class="border px-4 py-2 text-right">{{ eggBatch.status }}</td>
    <td class="border px-4 py-2 text-right">{{ hatchingSystems }}</td>
    <td class="border px-4 py-2 text-right whitespace-no-wrap">
      <action-button
        v-if="eggBatch.status === 'hatching-ongoing'"
        icon="sync-alt"
        @click.native="$emit('change-status')"
        class="mr-2"
      />
      <action-button
        v-if="eggBatch.status !== 'hatching-completed'"
        icon="edit"
        @click.native="$emit('edit')"
        class="mr-2"
      />
    </td>
  </tr>
</template>

<script>
import ActionButton from '@/components/global/form/ActionButton';
import LocalDate from '@/components/global/LocalDate';

export default {
  components: { LocalDate, ActionButton },
  props: {
    eggBatch: {
      type: Object,
      required: true
    }
  },
  computed: {
    hatchingSystems() {
      return this.eggBatch.hatching_processes
        .map(activity => activity.hatching_system_id)
        .filter((value, index, self) => self.indexOf(value) === index)
        .join(', ');
    }
  }
};
</script>
