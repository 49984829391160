<template>
  <modal-dialog :show="show" @close="close" width="600">
    <h1 class="text-center text-2xl font-bold mb-4">
      Rack Form
    </h1>
    <input-field
      v-model="form.name"
      label="Name"
      class="mb-6"
      :error="form.errors.get('name')"
    />

    <section-label label="Pupae door config" />

    <div class="mb-8">
      <div class="flex justify-center items-center mb-2">
        <div class="mr-4">Weekdays:</div>
        <select-weekdays-field
          v-model="weekdays"
          :initial-data="rack ? rack.weekdays : null"
          :error="form.errors.get('weekdays')"
          class="mr-4"
        />
      </div>
      <div class="flex justify-center">
        <div
          v-for="weekday in weekdays"
          :key="weekday.name"
          class="text-xs mr-1"
        >
          {{ weekday.short_name }},
        </div>
      </div>
    </div>

    <time-periods
      v-model="form.time_periods"
      :initial_time_periods="rack ? rack.time_periods : null"
      :error="form.errors.get('time_periods')"
    />

    <section-label label="Egg door config" />

    <div v-for="(eggDoor, index) in eggDoors" :key="eggDoor.id" class="mb-4">
      <div class="font-bold text-sm mb-4">Egg Door {{ index + 1 }}</div>
      <div class="flex items-center mb-4">
        <input-field
          class="w-48 mr-4"
          type="time"
          label="Opens at"
          :value="eggDoor.open_at"
          :error="form.errors.get(`egg_doors.${index}.open_at`)"
          @input="eggDoor.open_at = $event"
        />
        <div class="flex">
          <div class="mr-4">Weekdays:</div>
          <select-weekdays-field
            v-model="eggDoor.weekdays"
            :initial-data="eggDoor ? eggDoor.weekdays : null"
            :error="form.errors.get(`egg_doors.${index}.weekdays`)"
            class="mr-4"
          />
          <div
            v-for="weekday in eggDoor.weekdays"
            :key="weekday.name"
            class="text-xs mr-1"
          >
            {{ weekday.short_name }},
          </div>
        </div>
      </div>
      <div class="flex items-center">
        <label>Close After:</label>
        <input-field
          class="w-24 ml-4 mr-4"
          type="number"
          label="Hours"
          min="0"
          placeholder="hh"
          :value="eggDoor.close_after_hours"
          :error="form.errors.get(`egg_doors.${index}.close_after_hours`)"
          @input="eggDoor.close_after_hours = $event"
        />
        <input-field
          class="w-24"
          type="number"
          placeholder="mm"
          label="Minutes"
          min="0"
          max="59"
          :value="eggDoor.close_after_minutes"
          :error="form.errors.get(`egg_doors.${index}.close_after_minutes`)"
          @input="eggDoor.close_after_minutes = $event"
        />
      </div>
    </div>

    <div class="text-center mt-8">
      <primary-button :loading="processing" class="w-full mb-4" @click="submit">
        Submit
      </primary-button>
      <button @click="close" class="px-2 py-1">
        Close
      </button>
    </div>
  </modal-dialog>
</template>

<script>
import SelectWeekdaysField from '../global/form/SelectWeekdaysField';
import TimePeriods from './TimePeriods';

export default {
  components: {
    TimePeriods,
    SelectWeekdaysField
  },
  props: {
    show: {
      type: Boolean,
      required: true
    },
    flyRoom: {
      type: Object,
      required: true
    },
    rackManager: {
      type: Object,
      required: true
    },
    rack: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      processing: false,
      weekdays: [],
      eggDoors: [
        {
          open_at: null,
          close_after_hours: null,
          close_after_minutes: 0,
          weekdays: []
        },
        {
          open_at: null,
          close_after_hours: null,
          close_after_minutes: 0,
          weekdays: []
        }
      ],
      form: this.$form({
        name: null,
        weekdays: [],
        time_periods: []
      })
    };
  },
  watch: {
    rack: {
      immediate: true,
      handler(value) {
        if (value === null) {
          this.reset();
          return;
        }

        this.form.name = value.name;

        this.eggDoors[0].open_at = this.rack.egg_doors[0].open_at;
        this.eggDoors[0].close_after_hours = this.rack.egg_doors[0].close_after_hours;
        this.eggDoors[0].close_after_minutes = this.rack.egg_doors[0].close_after_minutes;
        this.eggDoors[0].weekdays = this.rack.egg_doors[0].weekdays;
        this.eggDoors[1].open_at = this.rack.egg_doors[1].open_at;
        this.eggDoors[1].close_after_hours = this.rack.egg_doors[1].close_after_hours;
        this.eggDoors[1].close_after_minutes = this.rack.egg_doors[1].close_after_minutes;
        this.eggDoors[1].weekdays = this.rack.egg_doors[1].weekdays;
      }
    }
  },
  methods: {
    close() {
      this.$emit('close');
    },
    submit() {
      this.rack ? this.edit() : this.create();
    },
    reset() {
      this.form.reset();
      this.eggDoors = [
        {
          open_at: null,
          close_after_hours: null,
          close_after_minutes: 0,
          weekdays: []
        },
        {
          open_at: null,
          close_after_hours: null,
          close_after_minutes: 0,
          weekdays: []
        }
      ];
      this.weekdays = [];
    },
    async create() {
      this.processing = true;
      try {
        this.form.weekdays = this.weekdays;
        this.form.egg_doors = this.eggDoors;
        await this.form.submit(
          'post',
          `/fly-rooms/${this.flyRoom.id}/rack-managers/${this.rackManager.id}/racks`
        );

        this.$emit('create');
        this.reset();
      } finally {
        this.processing = false;
      }
    },
    async edit() {
      this.processing = true;
      try {
        this.form.weekdays = this.weekdays;
        this.form.egg_doors = this.eggDoors;
        await this.form.submit(
          'patch',
          `/fly-rooms/${this.flyRoom.id}/rack-managers/${this.rackManager.id}/racks/${this.rack.id}`
        );

        this.$emit('edit');
        this.reset();
        this.close();
      } finally {
        this.processing = false;
      }
    }
  }
};
</script>
