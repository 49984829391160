<template>
  <fetch-json
    url="/fly-rooms"
    :filter="filter"
    disable-pagination
    class="text-sm"
  >
    <template v-slot:header>
      <div class="flex justify-between mb-4">
        <h2 class="font-bold text-xl">Fly Rooms</h2>
      </div>
    </template>
    <template slot-scope="{ response: flyRooms, reload }">
      <fly-room
        v-for="flyRoom in flyRooms"
        :key="flyRoom.id"
        :fly-room="flyRoom"
        @reload="reload"
      />

      <fly-room-form
        :show="flyRoomFormIsVisible"
        @create="onClose(reload)"
        @edit="onClose(reload)"
        @close="onClose"
      />
      <div class="text-center">
        <button
          @click="flyRoomFormIsVisible = true"
          class="px-4 py-2 bg-green-500 text-white font-bold rounded shadow-lg"
        >
          Add Fly Room
        </button>
      </div>
    </template>
  </fetch-json>
</template>

<script>
import FetchJson from '@/components/global/FetchJson';
import FlyRoomForm from '@/components/fly-rooms/FlyRoomForm';
import FlyRoom from '../../components/fly-rooms/FlyRoom';

export default {
  components: {
    FlyRoom,
    FlyRoomForm,
    FetchJson
  },
  data() {
    return {
      flyRoomFormIsVisible: false
    };
  },
  methods: {
    onClose(reload = null) {
      this.flyRoomFormIsVisible = false;
      if (reload) {
        reload();
      }
    }
  }
};
</script>
