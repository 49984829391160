<template>
  <div class="px-4 group hover:bg-gray-100">
    <div class="flex justify-between items-center group mb-2">
      <div class="py-1">
        <span
          class="inline-block w-2 h-2 rounded-full mr-2"
          :class="{
            'bg-blue-500': eggDoor.manually_opened_at,
            'bg-red-500': eggDoor.operation_halted_at,
            'bg-green-500':
              !eggDoor.manually_opened_at && !eggDoor.operation_halted_at
          }"
        ></span>
        <span>Door {{ number }}</span>
      </div>
      <div class="flex text-xs">
        <button
          v-if="eggDoor.operation_halted_at"
          @click="resolve"
          class="border-2 rounded bg-white text-gray-600 hover:border-blue-500 hover:text-blue-500 transition-all duration-300 font-bold px-1 mr-2"
        >
          resolve
        </button>
        <div
          v-if="showControls || eggDoor.operation_halted_at"
          :class="
            eggDoor.operation_halted_at ? '' : 'hidden group-hover:inline-block'
          "
        >
          <button
            v-if="openedEggDoors.includes(eggDoor.id)"
            class="border-2 rounded bg-white text-red-400 border-red-400 hover:border-red-600 hover:text-red-600 transition-all duration-300 font-bold px-1"
            @click="close"
          >
            close
          </button>
          <button
            v-else
            class="border-2 rounded bg-white text-blue-400 border-blue-400 hover:border-blue-600 hover:text-blue-600 transition-all duration-300 font-bold px-1"
            @click="open"
          >
            open
          </button>
        </div>
      </div>
    </div>
    <div class="text-xs text-gray-600">
      <div class="flex justify-between">
        <div>Opens at:</div>
        <div>{{ eggDoor.open_at }}</div>
      </div>
      <div class="flex justify-between">
        <div>Close after:</div>
        <div>{{ eggDoor.close_after }}</div>
      </div>
      <div class="flex justify-between">
        <div>On Weekdays:</div>
        <div>{{ weekdaysList }}</div>
      </div>
      <div v-if="eggDoor.manually_opened_at" class="flex justify-between">
        <div>Manually opened:</div>
        <div>
          {{ eggDoor.manually_opened_at }}
        </div>
      </div>
      <div v-if="eggDoor.operation_halted_at" class="flex justify-between">
        <div>Manually opened:</div>
        <div>
          {{ eggDoor.operation_halted_at }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rack: {
      type: Object,
      required: true
    },
    number: {
      type: Number,
      required: true
    },
    eggDoor: {
      type: Object,
      required: true
    },
    showControls: {
      type: Boolean,
      required: true
    },
    openedEggDoors: {
      type: Array,
      required: true
    }
  },
  computed: {
    weekdaysList() {
      if (!this.eggDoor.weekdays) {
        return;
      }

      const weekdays = [
        { name: 'Monday', short_name: 'Mon', value: 0 },
        { name: 'Tuesday', short_name: 'Tue', value: 1 },
        { name: 'Wednesday', short_name: 'Wed', value: 2 },
        { name: 'Thursday', short_name: 'Thu', value: 3 },
        { name: 'Friday', short_name: 'Fri', value: 4 },
        { name: 'Saturday', short_name: 'Sat', value: 5 },
        { name: 'Sunday', short_name: 'Sun', value: 6 }
      ];

      return this.eggDoor.weekdays
        .split(',')
        .map(
          weekday =>
            weekdays.find(day => day.value === parseInt(weekday)).short_name
        )
        .join(', ');
    }
  },
  methods: {
    open() {
      this.axios.post(`/racks/${this.rack.id}/egg-doors/${this.eggDoor.id}`);
    },
    close() {
      this.axios.delete(`/racks/${this.rack.id}/egg-doors/${this.eggDoor.id}`);
    },
    resolve() {
      this.axios.post(`/egg-doors/${this.eggDoor.id}/resolve-operation`);
    }
  }
};
</script>
