<template>
  <div>
    <dropdown-menu v-model="show" interactive class="relative">
      <button
        class="px-2 border rounded text-gray-500 hover:text-gray-600 transition-all duration-300"
        @click.stop="show = true"
      >
        <fa-icon :icon="['fas', 'calendar-day']" />
      </button>
      <div slot="dropdown" class="text-gray-600 px-4">
        <div
          v-for="weekday in weekdays"
          :key="weekday.name"
          class="flex items-center group cursor-pointer mb-2"
          @click="select(weekday)"
        >
          <fa-icon
            :icon="['fas', 'check']"
            class="text-green-500 group-hover:opacity-50 mr-2"
            :class="
              value.findIndex(item => item.value === weekday.value) >= 0
                ? 'opacity-100'
                : 'opacity-0'
            "
          />
          <div>{{ weekday.name }}</div>
        </div>
      </div>
    </dropdown-menu>
    <p v-if="error" class="text-xs text-red-500">{{ error }}</p>
  </div>
</template>

<script>
import DropdownMenu from '@innologica/vue-dropdown-menu';
import isEmpty from 'lodash.isempty';

export default {
  components: { DropdownMenu },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    initialData: {
      type: String,
      default: null
    },
    error: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      show: false,
      weekdays: [
        { name: 'Monday', short_name: 'Mon', value: 0 },
        { name: 'Tuesday', short_name: 'Tue', value: 1 },
        { name: 'Wednesday', short_name: 'Wed', value: 2 },
        { name: 'Thursday', short_name: 'Thu', value: 3 },
        { name: 'Friday', short_name: 'Fri', value: 4 },
        { name: 'Saturday', short_name: 'Sat', value: 5 },
        { name: 'Sunday', short_name: 'Sun', value: 6 }
      ]
    };
  },
  mounted() {
    if (!isEmpty(this.initialData) && this.initialData !== '') {
      let weekdays = this.initialData
        .split(',')
        .map(weekday =>
          this.weekdays.find(item => item.value === parseInt(weekday))
        );

      weekdays.sort((a, b) => a.value - b.value);

      this.$emit('input', weekdays);
    }
  },
  methods: {
    select(weekday) {
      let weekdays = [...this.value];
      const index = this.value.findIndex(item => item.value === weekday.value);

      index >= 0 ? weekdays.splice(index, 1) : weekdays.push(weekday);

      weekdays.sort((a, b) => a.value - b.value);

      this.$emit('input', weekdays);
    }
  }
};
</script>
