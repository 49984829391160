<template>
  <div>
    <div class="flex items-center mb-4">
      <div class="font-bold text-lg">#{{ flyRoom.id }} {{ flyRoom.name }}</div>

      <div class="flex space-x-4 ml-4">
        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="flyRoomFormIsVisible = true"
        >
          edit
        </button>

        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="showDeleteDialog = true"
        >
          delete
        </button>

        <button
          class="text-xs text-gray-700 bg-white px-2 border rounded font-bold shadow"
          @click="rackManagerFormIsVisible = true"
        >
          add controller
        </button>
      </div>
    </div>

    <rack-manager
      v-for="rackManager in flyRoom.rack_managers"
      :key="rackManager.id"
      :fly-room="flyRoom"
      :rack-manager="rackManager"
      class="mb-8"
      @reload="$emit('reload')"
    />

    <rack-manager-form
      :show="rackManagerFormIsVisible"
      :fly-room="flyRoom"
      @create="onDone"
      @close="onClose"
    />

    <fly-room-form
      :show="flyRoomFormIsVisible"
      :fly-room="flyRoom"
      @create="onDone"
      @edit="onDone"
      @close="onClose"
    />

    <confirm-action-dialog
      :show="showDeleteDialog"
      type="delete"
      width="600"
      :resource="flyRoom"
      :action="deleteFlyRoom"
      @close="onClose"
      @done="onDone"
    >
      <template slot="title"> Delete Fly Room #{{ flyRoom.id }}? </template>
      <p>
        All the controllers and racks assigned with this fly room will also be
        deleted. Are you sure you want to delete selected fly room
        <strong>{{ flyRoom.name }}</strong
        >?
      </p>
    </confirm-action-dialog>
  </div>
</template>

<script>
import RackManagerForm from './RackManagerForm';
import RackManager from './RackManager';
import FlyRoomForm from './FlyRoomForm';

export default {
  components: { FlyRoomForm, RackManager, RackManagerForm },
  props: {
    flyRoom: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      rackManagerFormIsVisible: false,
      flyRoomFormIsVisible: false,
      showDeleteDialog: false
    };
  },
  methods: {
    onDone() {
      this.onClose();
      this.$emit('reload');
    },
    onClose() {
      this.rackManagerFormIsVisible = false;
      this.flyRoomFormIsVisible = false;
      this.showDeleteDialog = false;
    },
    async deleteFlyRoom() {
      await this.axios.delete(`/fly-rooms/${this.flyRoom.id}`);
    }
  }
};
</script>
