<template>
  <fetch-json url="/egg-batches" :filter="filter">
    <template v-slot:header>
      <div class="flex justify-between">
        <h1 class="text-2xl font-bold">Egg batches</h1>
        <primary-button @click="showEggBatchDialog = true">
          + Add New
        </primary-button>
      </div>
    </template>
    <template v-slot:controls>
      <select-field
        v-model="filter.status"
        label="Status"
        :reduce="option => option.value"
        :fixed-options="statuses"
        :move-label="false"
        class="w-64 shadow-md bg-white rounded"
      />
    </template>
    <template v-slot:default="{ response: eggBatches, reload }">
      <table class="table-auto bg-white rounded-lg shadow-md w-full">
        <thead>
          <tr class="text-sm">
            <th class="text-left border px-4 py-2">Egg batch ID</th>
            <th class="text-left border px-4 py-2">Start Date</th>
            <th class="text-left border px-4 py-2">End Date</th>
            <th class="text-right border px-4 py-2">Estimated Count</th>
            <th class="text-right border px-4 py-2">Total larvae count</th>
            <th class="text-right border px-4 py-2">Hatching Rate</th>
            <th class="text-right border px-4 py-2">Status</th>
            <th class="text-right border px-4 py-2">Hatching System #</th>
            <th class="text-right border px-4 py-2">Actions</th>
          </tr>
        </thead>
        <tbody>
          <egg-batch-list-item
            v-for="eggBatch in eggBatches"
            :key="eggBatch.id"
            :egg-batch="eggBatch"
            class="mb-4"
            @edit="onEdit(eggBatch)"
            @change-status="onChangeStatus(eggBatch)"
          />
        </tbody>
      </table>

      <egg-batch-form
        v-if="showEggBatchDialog"
        :show="showEggBatchDialog"
        @close="onClose"
        @create="reload"
        @edit="reload"
        :egg-batch="selectedEggBatch"
      />

      <change-egg-batch-status-dialog
        :show="showChangeStatusDialog"
        :egg-batch="selectedEggBatch"
        @close="onClose"
        @update="onStatusUpdate(reload)"
      />
    </template>
  </fetch-json>
</template>

<script>
import qs from 'qs';
import FetchJson from '@/components/global/FetchJson';
import EggBatchListItem from '@/components/egg-batches/EggBatchListItem';
import EggBatchForm from '@/components/egg-batches/EggBatchForm';
import ChangeEggBatchStatusDialog from '@/components/egg-batches/ChangeEggBatchStatusDialog';

export default {
  name: 'EggBatches',
  components: {
    ChangeEggBatchStatusDialog,
    EggBatchForm,
    EggBatchListItem,
    FetchJson
  },
  data() {
    return {
      showEggBatchDialog: false,
      showChangeStatusDialog: false,
      selectedEggBatch: null,
      statuses: [
        { name: 'All status', value: null },
        { name: 'Hatching ongoing', value: 'hatching-ongoing' },
        { name: 'Not hatched', value: 'not-hatched' }
      ],
      filter: {
        status: null
      }
    };
  },
  created() {
    if (this.$route.query.filter) {
      this.filter = qs.parse(this.$route.query.filter);
    }
  },
  methods: {
    onClose() {
      this.selectedEggBatch = null;
      this.showEggBatchDialog = false;
      this.showChangeStatusDialog = false;
    },
    onEdit(eggBatch) {
      this.selectedEggBatch = eggBatch;
      this.showEggBatchDialog = true;
    },
    onChangeStatus(eggBatch) {
      this.selectedEggBatch = eggBatch;
      this.showChangeStatusDialog = true;
    },
    onStatusUpdate(reload) {
      this.onClose();
      reload();
    }
  }
};
</script>
